<template>
  <div id="hero" v-scroll="handleScroll">
    <div class="container">
      <div class="content">
        <div class="title">
          Os problemas na sua viagem valem até 600€ em compensação
        </div>
        <div class="description">
          Se esteve num <strong>voo atrasado</strong> ou <strong>cancelado</strong> nos últimos 5 anos, envie-nos a sua reclamação em menos de 3 minutos e a LiberFly irá avaliar, <strong>gratuitamente</strong>, o seu caso. Pode <strong>receber até 600€</strong> em compensação!
        </div>
        <a href="https://reclamacao.liberfly.pt" class="btn btn-action">
          Enviar Reclamação
        </a>
      </div>
    </div>
    <div class="bg-images">
      <img class="plane" :style="{width: `calc(80% - ${scrollTop}px`}" src="@/assets/bg-plane.svg" alt="Avião">
      <img class="mountains" src="@/assets/bg-mountains.svg" alt="Montanhas">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0,
    };
  },
  methods: {
    handleScroll(e) {
      if (screen.width > 576) {
        this.scrollTop = e.target.documentElement.scrollTop * 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#hero {
  width: 100%;
  height: 726px;
  padding-top: 162px;
  position: relative;
  background: linear-gradient(
    180deg,
    $blue-primary 0%,
    $blue-secondary 54.17%,
    $blue-light 100%
  );
  overflow: hidden;

  @media (max-width: 576px) {
    padding-top: 81px;
    padding-bottom: 146px;
    height: auto;
  }

  .container {
    position: relative;
    height: 100%;

    .content {
      color: $white;

      .title {
        font-weight: $semibold;
        font-size: 42px;
        line-height: 51px;
        margin-bottom: 15px;
        max-width: 750px;

        @media (max-width: 576px) {
          font-size: 40px;
          line-height: 46px;
        }
      }

      .description {
        font-weight: $medium;
        font-size: 18px;
        line-height: 22px;
        color: $white-secondary;
        margin-bottom: 65px;
        max-width: 586px;

        @media (max-width: 576px) {
          font-size: 14px;
          margin-bottom: 55px;
        }

        strong {
          font-weight: $medium;
          color: $yellow;
        }
      }

      .btn {
        height: 50px;
        max-width: 334px;
        font-weight: $bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        @media (max-width: 576px) {
          font-size: 14px;
          max-width: 100%;
        }
      }
    }
  }

  .bg-images {
    pointer-events: none;
    max-width: 1340px;
    margin: 0 auto;
    position: relative;
    bottom: 123px;

    @media (max-width: 1920px) {
      position: absolute;
      bottom: -1px;
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 576px) {
      position: absolute;
      bottom: -1px;
      width: 100%;
      bottom: -1px;
    }

    .plane {
      position: absolute;
      bottom: 0;
      left: 120px;
      max-width: 1200px;

      @media (max-width: 1920px) {
        width: 80%;
        max-width: 1200px;
        height: auto;
        display: block;
        pointer-events: none;
        position: relative;
        top: 0;
        left: 15%;
        z-index: 0;
      }

      @media (max-width: 576px) {
        bottom: 0;
        top: 0;
      }
    }

    .mountains {
      max-width: 100%;
      position: relative;
      left: 0;
      margin: 0 auto;
      display: block;

      @media (max-width: 1920px) {
        width: 101%;
        max-width: 101%;
        position: absolute;
        bottom: 0;
        left: -10px;
        pointer-events: none;
        z-index: 1;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="home">
      <HomeHero />
      <HomeAwards />
      <HomeSteps />
      <HomeDeal />
      <CallToAction />
      <Reports />
      <Footer :hasCall="false" />
    </div>
  </div>
</template>

<script>
import HomeHero from "@/components/Home/Hero.vue";
import HomeAwards from "@/components/Home/Awards.vue";
import HomeSteps from "@/components/Home/Steps.vue";
import HomeDeal from "@/components/Home/Deal.vue";
import CallToAction from "@/components/CallToAction.vue";
import Reports from "@/components/Reports.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    HomeHero,
    HomeAwards,
    HomeSteps,
    HomeDeal,
    CallToAction,
    Reports,
    Footer,
  },
  jsonld: {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://www.liberfly.pt/",
    name: "LiberFly | Receba uma compensação por Voos Atrasados ou Cancelados",
    alternateName: "Pode receber até 600€ por passageiro! | LiberFly",
    description:
      "A LiberFly é a maior empresa de Resolução de Problemas de Voos da América Latina e chega agora a Portugal para tratar do seu caso, sem custos nem burocracias!",
    keywords:
      "voo,atrasado,cancelado,overbooking,direito,indenização,perdi,extraviada,avião,passagem,viagem,bagagem,danificada,aério,compensação,voo,no-show",
  },
  mounted() {
    const meta = document.querySelector('meta[name="robots"]');
    if (this.$route.meta.robots) {
      meta.setAttribute("content", this.$route.meta.robots);
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: $blue-primary;
  padding-top: 60px;
}
</style>

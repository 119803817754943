<template>
  <section id="steps">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="left">
            <div class="title">
              Obtenha já a sua compensação
            </div>
            <div class="description">
              Siga os passos abaixo para reivindicar os seus direitos
            </div>
            <div class="clickstep" v-for="(step, i) in steps" :class="{active: activeStep >= i}" :key="i" @click="changeStep(step, i)">
              <div class="icon">
                <font-awesome-icon :icon="['fas', step.icon]" />
              </div>
              <div class="balloon">
                <div class="text">
                  <span class="number">{{ step.number }}</span>
                  {{ step.title }}
                </div>
                <div class="info" v-html="step.subtitle">
                </div>
              </div>
              <div class="previousdots" v-if="i != 0">
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
              <div class="nextdots">
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="right">
            <div class="content">
              <div class="info">
                <img class="logo" src="@/assets/logo-white.png" alt="Logomarca da LiberFly">
                <div class="number">
                  {{ stepNumber }}
                </div>
                <div class="text" v-html="stepText">
                </div>
              </div>
              <div class="line">
                <div class="item" v-for="(step, i) in steps" :class="{active: activeStep == i}" :key="i" @click="changeStep(step, i)">
                  {{ step.number }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeStep: 0,
      stepText:
        "Voo Atrasado ou Cancelado? Problemas com a bagagem? Não deixe de reclamar! Tratamos de tudo por si!",
      stepNumber: "01",
      steps: [
        {
          number: "01",
          title: "O que aconteceu?",
          subtitle:
            "Para problemas recentes ou que aconteceram nos <strong>últimos 5 anos</strong>",
          description:
            "Voo Atrasado ou Cancelado? Problemas com a bagagem? Não deixe de reclamar! Tratamos de tudo por si!",
          icon: "plane",
        },
        {
          number: "02",
          title: "Como aconteceu?",
          subtitle:
            "Preencha o nosso formulário em <strong>menos de 3 minutos</strong>",
          description:
            '<div>Conte-nos o que aconteceu para podermos resolver o problema rapidamente.</div><a href="https://reclamacao.liberfly.pt" class="btn btn-action">Enviar Reclamação</a>',
          icon: "file-contract",
        },
        {
          number: "03",
          title: "Nós entraremos em contacto",
          subtitle:
            "Aguarde um contacto da LiberFly em <strong>48h úteis!</strong>",
          description:
            "Depois de preencher o formulário, irá receber todas as informações por e-mail, ligação ou WhatsApp",
          icon: "mobile",
        },
        {
          number: "04",
          title: "Relaxe enquanto tratamos de tudo!",
          subtitle:
            "A LiberFly irá resolver a situação junto da companhia aérea",
          description:
            "Neste passo não terá de fazer nada! Sem burocracias ou papelada, nós iremos contactar a campanhia aérea e defender os seus direitos!",
          icon: "user-clock",
        },
        {
          number: "05",
          title: "Receba a sua compensação!",
          subtitle:
            "Transferimos o valor para <strong>a sua conta bancária</strong>",
          description:
            "Esta é a melhor parte! Receba o dinheiro na sua conta e prepare-se para embarcar de novo numa aventura pelo mundo! ",
          icon: "coins",
        },
      ],
    };
  },
  methods: {
    changeStep(step, i) {
      this.activeStep = i;
      this.stepText = step.description;
      this.stepNumber = step.number;
    },
  },
};
</script>

<style lang="scss">
#steps {
  .container {
    background: $white;
    padding-top: 99px;

    @media (max-width: 576px) {
      padding-top: 50px;
    }
  }

  .row {
    .left {
      color: $black;

      .title {
        font-weight: $bold;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 15px;
        text-align: center;

        @media (max-width: 576px) {
          font-size: 26px;
          line-height: 30px;
        }
      }

      .description {
        font-weight: $medium;
        font-size: 14px;
        line-height: 20px;
        max-width: 533px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 43px;
        text-align: center;
      }

      .clickstep {
        display: flex;
        margin-bottom: 36px;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        &:last-child {
          .nextdots {
            display: none;
          }
        }

        &:nth-child(1) {
          .previousdots {
            display: none;
          }
        }

        &.active {
          .icon {
            svg {
              color: $blue-primary;
            }
          }

          .balloon {
            background: $blue-primary;
            color: $white;

            .text {
              &:before {
                border-right: 10px solid $blue-primary;
              }

              .number {
                color: $yellow;
              }
            }

            .info {
              strong {
                color: $yellow;
              }
            }
          }

          .nextdots {
            .dot {
              background: $blue-primary;
            }
          }

          .previousdots {
            .dot {
              background: $blue-primary;
            }
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          margin-right: 18px;

          @media (max-width: 576px) {
            width: 35px;
            margin-right: 12px;
            align-items: flex-start;
            padding-top: 20px;
          }

          svg {
            font-size: 26px;
            color: $grey-secondary;

            @media (max-width: 991px) {
              color: $blue-primary;
            }
          }
        }

        .balloon {
          width: 100%;
          padding: 13px 23px;
          background: $grey-secondary;
          font-weight: $medium;
          position: relative;

          @media (max-width: 991px) {
            background: $blue-primary;
            color: $white;

            strong {
              color: $yellow;
            }
          }

          .text {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 5px;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid $grey-secondary;
              position: absolute;
              top: 20px;
              left: -5px;

              @media (max-width: 991px) {
                border-right: 10px solid $blue-primary;
              }
            }

            .number {
              @media (max-width: 991px) {
                color: $yellow;
              }
            }
          }

          .info {
            font-size: 12px;
            line-height: 15px;

            strong {
              font-weight: $medium;
            }
          }
        }

        .previousdots {
          position: absolute;
          top: -15px;
          left: 27px;

          @media (max-width: 576px) {
            left: 15px;
          }

          .dot {
            width: 6px;
            height: 6px;
            background: $grey-secondary;
            border-radius: 3px;
            margin-bottom: 7px;

            @media (max-width: 991px) {
              background: $blue-primary;
            }
          }
        }

        .nextdots {
          position: absolute;
          bottom: -20px;
          left: 27px;

          @media (max-width: 576px) {
            left: 15px;
          }

          .dot {
            width: 6px;
            height: 6px;
            background: $grey-secondary;
            border-radius: 3px;
            margin-bottom: 7px;

            @media (max-width: 991px) {
              background: $blue-primary;
            }
          }
        }
      }
    }

    .right {
      background: $blue-primary;
      position: relative;
      right: -15px;
      padding: 20px;

      @media (max-width: 991px) {
        display: none;
      }

      .content {
        background: $blue-dark;
        padding: 16px;
        font-weight: $regular;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 600px;
        color: $white;

        .logo {
          display: block;
          width: 115px;
          height: auto;
          margin-bottom: 75px;
        }

        .number {
          color: $yellow;
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 14px;
        }

        .text {
          max-width: 428px;
          font-size: 24px;
          line-height: 38px;

          .btn {
            margin-top: 35px;
            max-width: 200px;
          }
        }

        .line {
          display: flex;

          .item {
            cursor: pointer;
            font-weight: $medium;
            font-size: 18px;
            width: 100%;
            line-height: 38px;
            border-bottom: 4px solid $white;

            &.active {
              color: $yellow;
              border-bottom: 4px solid $yellow;
            }
          }
        }
      }
    }
  }
}
</style>
